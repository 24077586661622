.content-area {
  margin-top: 5em;
  padding-top: 2em;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}

.c-h1 {
  font-family: sans-serif;
  font-size: 2.5rem;
  font-weight: 500;
}

table {
  margin-top: 5rem !important;
  thead {
    box-shadow: 0 1px 8px 0 rgba(34, 36, 38, 0.15) !important;
  }
  tbody {
    tr {
      line-height: 32px;
      
    }
  }
}
