header {
  .ui.menu {
    position: relative;
    background-color: #f8f9fa !important;
    font-size: 15px;
    .item:before {
      width: 0;
    }
  }
}
